import React from "react";
import { Header } from "../components/Header";





export const Navbar = () => {
    return (
    <>
      <Header />
    
    </>
    );
};
