import React from "react";
import { PageNotFound } from "../components/PageNotFound";




const Error404 = () => {
    return(
        <PageNotFound />
    )
}


export default Error404;